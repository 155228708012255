import React from 'react';
import { db } from "../services/firebase";
import PropTypes from 'prop-types'
import ProfileComponent from '../components/ProfileComponent'
import { withStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile';
import ReceivedRequestDialog from '../components/ReceivedRequestDialog'
import SentRequestDialog from '../components/SentRequestDialog'

const styles = {
  root: {
    "display": 'flex',
    "flexWrap": 'wrap',
    "justifyContent": 'space-around',
  },
  gridList: {
    "width": '100%',
    "height": '100%',
    "overflowY": 'auto',
  },
  gridListTile: {
    padding: 16,
  },
};

class Lobby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participants: new Map(),
      sentRequestTo: null,
      sentRequestStatus: null,
      receivedRequestFrom: null,
      receivedRequestStatus: null,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    const { eventId, userId } = this.props;
    const participantsRef = db.ref("participants/" + eventId);

    // Initial lookup so we properly set the order for initial participants.
    participantsRef.once("value", snapshot => {
      const availableParticipants = [];
      const chattingParticipants = [];
      snapshot.forEach(function(childSnapshot) {
        const childData = childSnapshot.val();
        if (!childData["connected"]) {
          return;
        }
        if (childData["chatting"]) {
          chattingParticipants.push(childSnapshot);
        } else if (childData["available"]) {
          availableParticipants.push(childSnapshot);
        }
      });
      this.shuffle(availableParticipants);
      this.shuffle(chattingParticipants);
      const participants = new Map();
      // Add this user manually
      participants.set(userId, {"available": true, "chatting": false});
      availableParticipants.forEach(function(childSnapshot) {
        participants.set(childSnapshot.key, childSnapshot.val());
      });
      chattingParticipants.forEach(function(childSnapshot) {
        participants.set(childSnapshot.key, childSnapshot.val());
      });
      this.setState({
        participants: participants,
      })

      // Only after it finishes, add listeners for changes.
      // Listen for participants being added (note: this will fire for initial
      // participants that have already been added as well).
      participantsRef.on("child_added", data => {
        // If it's in the initial list of participants, skip it.
        if (participants.has(data.key)) {
          return;
        }
        this.setState(prevState => {
          const participantStatus = data.val();

          // Only add them if they are chatting or available.
          if (participantStatus["connected"]
              && (participantStatus["chatting"] || participantStatus["available"])) {
            prevState.participants.set(data.key, participantStatus);
            return {
              participants: prevState.participants
            };
          }
        });
      });

      // Listen for participants being updated.
      participantsRef.on("child_changed", data => {
        this.setState(prevState => {
          const participantId = data.key;
          const participantStatus = data.val();

          // Only update them if they were there previously or if they are
          // chatting or available.
          if (prevState.participants.has(participantId)
              || (participantStatus["connected"]
              && (participantStatus["chatting"] || participantStatus["available"]))) {
            prevState.participants.set(data.key, participantStatus);
            return {
              participants: prevState.participants
            };
          }
        });
      });
    });

    const sentRequestsRef = db.ref("sentRequests/" + eventId + "/" + userId);
    sentRequestsRef.on("child_added", data => {
      this.setState({
        sentRequestTo: data.key,
        sentRequestStatus: data.val(),
      });
    });
    sentRequestsRef.on("child_changed", data => {
      this.setState({
        sentRequestStatus: data.val(),
      });
    });
    sentRequestsRef.on("child_removed", () => {
      this.setState({
        sentRequestTo: null,
        sentRequestStatus: null,
      });
    });

    const receivedRequestsRef = db.ref("receivedRequests/" + eventId + "/" + userId);
    receivedRequestsRef.on("value", snapshot => {
      if (!snapshot.exists()) {
        this.setState({
          receivedRequestFrom: null,
          receivedRequestStatus: null,
        });
        return;
      }
      snapshot.forEach(childSnapshot => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        this.setState(prevState => {
          if (prevState.receivedRequestFrom == null && childData !== "declined") {
            return {
              receivedRequestFrom: childKey,
              receivedRequestStatus: childData,
            }
          }
          if (childKey === prevState.receivedRequestFrom) {
            if (childData === "declined") {
              return {
                receivedRequestFrom: null,
                receivedRequestStatus: null,
              };
            }
            return {
              receivedRequestStatus: childData,
            };
          }
          // If childKey is not for the current receivedRequestFrom, ignore it.
        });
      })
    });

    db.ref("participants/" + eventId + "/" + userId).update({
      available: true,
    });

    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount () {
    const { eventId, userId } = this.props;
    db.ref("participants/" + eventId).off();
    db.ref("participants/" + eventId + "/" + userId).update({
      available: false
    }, function(error) {
      // Ignore the error. It's probably permission denied as a result of having
      // already signed out.
    });
    db.ref("sentRequests/" + eventId + "/" + userId).off();
    db.ref("receivedRequests/" + eventId + "/" + userId).off();
    window.removeEventListener('resize', this.updateDimensions);
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  updateDimensions() {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  }

  render() {
    const { eventId, userId, onStartEditingProfile } = this.props;
    const { participants, sentRequestTo, sentRequestStatus, receivedRequestFrom, receivedRequestStatus, windowWidth } = this.state;
    const profiles = [];
    for (let [key, value] of participants) {
      profiles.push(
        <GridListTile key={key} style={styles.gridListTile}>
          <ProfileComponent
            eventId={eventId}
            userId={userId}
            profileUser={key}
            isSelf={userId === key}
            isAvailable={value["available"] && value["connected"]}
            isChatting={value["chatting"] && value["connected"]}
            onStartEditingProfile={onStartEditingProfile}
          />
        </GridListTile>);
    }
    // FIXME(TESTING): For testing purposes, if I have sent and received to myself
    // randomly decide to show sent or received.
    // const testSentRequestTo = (Math.random() < 0.5) ? sentRequestTo : null;
    const testSentRequestTo = sentRequestTo;
    const numColumns = Math.max(1, Math.floor(windowWidth / 320));
    return (
      <div style={styles.root}>
        <GridList
          cellHeight={544}
          style={styles.gridList}
          cols={numColumns}
        >
          {profiles}
        </GridList>
        {testSentRequestTo != null &&
        <SentRequestDialog
          eventId={eventId}
          userId={userId}
          sentRequestTo={sentRequestTo}
          sentRequestStatus={sentRequestStatus}
        />
        }
        {testSentRequestTo == null && receivedRequestFrom != null &&
        <ReceivedRequestDialog
          eventId={eventId}
          userId={userId}
          receivedRequestFrom={receivedRequestFrom}
          receivedRequestStatus={receivedRequestStatus}
        />
        }
      </div>
    );
  }
}

Lobby.propTypes = {
  classes: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  onStartEditingProfile: PropTypes.func.isRequired,
}

export default withStyles(styles)(Lobby);