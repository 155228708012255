import React from "react";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { auth } from "../services/firebase";
import { AppBar, Toolbar, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import flowOne from './flow1.png';
import flowTwo from './flow2.png';
import flowThree from './flow3.jpg';
import Container from '@material-ui/core/Container'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Don't do anything upon signin. We already are listening for auth state changes.
  callbacks: {
    signInSuccessWithAuthResult: function() {
      return false;
    }
  },
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    auth.GoogleAuthProvider.PROVIDER_ID,
    //auth.FacebookAuthProvider.PROVIDER_ID
  ],
  tosUrl: 'https://www.termsfeed.com/live/029c6b1b-539f-4dc1-9f6c-80eb75a9020e',
  // Privacy policy url/callback.
  privacyPolicyUrl: 'https://www.termsfeed.com/live/85305a6b-ecdb-4cd3-a7d0-d7264f81e02c'
};

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(500 + theme.spacing.unit * 3 * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  title: {
    flexGrow: 1,
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  button: {
    margin: 'auto',
    width: 144,
  },
});

class Login extends React.Component {
  render() {
    const { classes, eventName } = this.props;
    let loginElement;
    if (eventName != null) {
      loginElement = (
        <Paper className={classes.paper}>
          <Typography variant="h6">
            Join the event: {eventName}
          </Typography>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()}/>
        </Paper>
      );
    } else {
      loginElement = (
        <Paper className={classes.paper}>
          <Typography variant="h6">
            Interested in holding an event?
          </Typography>
          <Button
            target="_blank"
            variant="contained"
            color="primary"
            href="mailto:hello@somebodyyoushouldmeet.com"
            className={classes.button}
          >
            Contact Us
          </Button>
        </Paper>
      );
    }
    return (
      <main className={classes.main}>
        <CssBaseline />
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Somebody You Should Meet
            </Typography>
          </Toolbar>
        </AppBar>
        <Container className={classes.cardGrid} maxWidth="100%">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6">
                    Step 1: Enter the room and see who&apos;s there
                  </Typography>
                </CardContent>
                <CardMedia
                  className={classes.cardMedia}
                  image={flowOne}
                  title="Enter"
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6">
                    Step 2: Strike up a 1-on-1 conversation
                  </Typography>
                </CardContent>
                <CardMedia
                  className={classes.cardMedia}
                  image={flowTwo}
                  title="Start"
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6">
                    Step 3: Chat away, and then repeat!
                  </Typography>
                </CardContent>
                <CardMedia
                  className={classes.cardMedia}
                  image={flowThree}
                  title="Chat"
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="xs">
          {loginElement}
        </Container>
      </main>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  eventName: PropTypes.string,
}

export default withStyles(styles)(Login);
