import React from 'react';
import PropTypes from 'prop-types'
import { db } from '../services/firebase'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import { Typography } from '@material-ui/core'

const styles = {
}

class ReceivedRequestDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      photo: null,
      aboutMe: null,
    };
    this.handleAccept = this.handleAccept.bind(this);
    this.handleDecline = this.handleDecline.bind(this);
  }

  componentDidMount() {
    const { eventId, userId, receivedRequestFrom } = this.props;
    db.ref("participants/" + eventId + "/" + userId).update({
      chatting: true
    });
    db.ref("profiles/" + eventId + "/" + receivedRequestFrom).on("value", snapshot => {
      if (snapshot.exists()) {
        const profile = snapshot.val();
        this.setState({
          name: profile.name,
          photo: profile.photo,
          aboutMe: profile.aboutMe,
        });
      }
    });
  }

  componentWillUnmount () {
    const { eventId, userId, receivedRequestFrom } = this.props;
    db.ref("participants/" + eventId + "/" + userId).update({
      chatting: false
    }, function(error) {
      // Ignore the error. It's probably permission denied as a result of having
      // already signed out.
    });
    db.ref("profiles/" + eventId + "/" + receivedRequestFrom).off();
  }

  handleAccept() {
    const { eventId, userId, receivedRequestFrom} = this.props;
    const updates = {};
    updates["sentRequests/" + eventId + "/" + receivedRequestFrom + "/" + userId] = "accepted";
    updates["receivedRequests/" + eventId + "/" + userId + "/" + receivedRequestFrom] = "accepted";
    db.ref().update(updates);
    // FIXME: If this receives a permissions error, delete both entries.
  }

  handleDecline() {
    const { eventId, userId, receivedRequestFrom} = this.props;
    const updates = {};
    updates["sentRequests/" + eventId + "/" + receivedRequestFrom + "/" + userId] = "declined";
    updates["receivedRequests/" + eventId + "/" + userId + "/" + receivedRequestFrom] = "declined";
    db.ref().update(updates);
  }

  render () {
    const { classes, receivedRequestStatus } = this.props;
    const { name, photo, aboutMe } = this.state;
    if (receivedRequestStatus === "accepted") {
      return (
        <Dialog
          onClose={this.handleDecline}
          aria-labelledby="dialog-title"
          open={true}
          disableBackdropClick={true}
        >
          <DialogTitle id="dialog-title">The chat is being set up...</DialogTitle>
        </Dialog>
      );
    }
    if (name == null) {
      return (
        <Dialog
          onClose={this.handleDecline}
          aria-labelledby="dialog-title"
          open={true}
          disableBackdropClick={true}
        >
          <DialogTitle id="dialog-title">Someone would like to chat!</DialogTitle>
        </Dialog>
      );
    }
    return (
      <Dialog
        onClose={this.handleDecline}
        aria-labelledby="dialog-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="dialog-title">Someone would like to chat!</DialogTitle>
        <img
          src={photo}
          alt={name}
          width={288}
          height={288}
        />
        <DialogContent>
          <Typography gutterBottom variant="h5" component="h2">
            {name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {aboutMe}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.handleDecline}
            className={classes.button}
          >
            Decline
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleAccept}
            className={classes.button}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ReceivedRequestDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  receivedRequestFrom: PropTypes.string.isRequired,
  receivedRequestStatus: PropTypes.string.isRequired,
};

export default withStyles(styles)(ReceivedRequestDialog);