import React from 'react';
import './VideoChat.css';
import {
  OTSession, OTPublisher, OTStreams, OTSubscriber,
} from 'opentok-react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button'
import { db } from '../services/firebase'

const styles = {
  videos: {
    position: 'relative',
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  subscriber: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
  publisher: {
    position: 'absolute',
    width: '360px',
    height: '240px',
    bottom: '10px',
    left: '10px',
    zIndex: 100,
    border: '3px solid white',
    borderRadius: '3px',
  },
  button: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    zIndex: 200,
  },
};

class VideoChat extends React.Component {
  constructor(props) {
    super(props);
    this.handleLeaveChat = this.handleLeaveChat.bind(this);
  }

  componentDidMount() {
    const { eventId, userId } = this.props;
    db.ref("participants/" + eventId + "/" + userId).update({
      chatting: true
    });
  }

  componentWillUnmount () {
    const { eventId, userId } = this.props;
    db.ref("participants/" + eventId + "/" + userId).update({
      chatting: false
    }, function(error) {
      // Ignore the error. It's probably permission denied as a result of having
      // already signed out.
    });
  }

  handleLeaveChat() {
    const { eventId, userId } = this.props;
    db.ref("chats/" + eventId + "/" + userId).set(null);
  }

  render() {
    const { classes, sessionId, token } = this.props;
    const publisherProperties = {
      width: '100%',
      height: '100%',
    };
    const subscriberProperties = {
      width: '100%',
      height: '100%',
    };
    return (
      <div className={classes.videos}>
        <OTSession apiKey="46435992" sessionId={sessionId} token={token}>
          <div id="publisher" className={classes.publisher}>
            <OTPublisher properties={publisherProperties} />
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleLeaveChat}
              className={classes.button}
            >
              Leave Chat
            </Button>
          </div>
          <div id="subscriber" className={classes.subscriber}>
            <OTStreams>
              <OTSubscriber properties={subscriberProperties} />
            </OTStreams>
          </div>
        </OTSession>
      </div>
    );
  }
}

VideoChat.propTypes = {
  classes: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default withStyles(styles)(VideoChat);
