import React, { Component } from 'react';
import {
  AppBar, Toolbar, withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = {
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
};

class NavBar extends Component {
  render() {
    const { classes, eventName, onLogout } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Welcome to {eventName}
            </Typography>
            <Button color="inherit" onClick={onLogout}>Logout</Button>
          </Toolbar>
        </AppBar>
      </div>

    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  eventName: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default withStyles(styles)(NavBar);
