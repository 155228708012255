import React, { Component } from 'react';
import Lobby from "./pages/Lobby";
import Login from "./pages/Login";
import ProfilePage from "./pages/ProfilePage";
import { auth, db } from "./services/firebase";
import './App.css';
import VideoChat from './pages/VideoChat'
import NavBar from './components/NavBar'

class App extends Component {
  constructor(props) {
    super(props);
    this.urlEventId = null;  // The event ID in the URL, which may not exist.
    this.user = null;
    this.state = {
      eventName: null,
      eventLoading: true,
      user: null,
      userLoading: true,
      profile: null,
      profileLoading: true,
      profileEditing: false,
      chatSessionId: null,
      chatToken: null
    };
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
    this.handleStartEditingProfile = this.handleStartEditingProfile.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    this.urlEventId = window.location.pathname.replace(/^\/+/, "").split("/")[0];
    this.startListeningForEvent();
    this.startListeningForUser();
  }

  // Listen for event details (currently whether it exists or not).
  startListeningForEvent() {
    if (!this.urlEventId || this.urlEventId.length === 0) {
      this.setState({
        eventName: null,
        eventLoading: false
      });
      return;
    }
    db.ref("events/" + this.urlEventId).on("value", snapshot => {
      this.setState({
        eventLoading: false,
        eventName: snapshot.exists() ? snapshot.val() : null
      });
    });
  }

  // Listen for user details (which also affects profile details)
  startListeningForUser() {
    auth().onAuthStateChanged(newUser => {
      this.setUser(newUser);
    });
  }

  setUser(newUser) {
    this.stopListeningForChat();
    this.stopListeningForProfile();
    this.stopListeningForConnected();

    this.user = newUser;
    this.setState({
      user: this.user,
      userLoading: false,
      profile: null,
      profileLoading: true,
      profileEditing: false,
      chatSessionId: null,
      chatToken: null
    });

    this.addParticipantInDb();
    this.startListeningForConnected();
    this.startListeningForProfile();
    this.startListeningForChat();
  }

  // Detach listener for profile for this.user.
  stopListeningForProfile() {
    if (this.user != null) {
      db.ref("profiles/" + this.urlEventId + "/" + this.user.uid).off();
    }
  }

  // Attach listener for profile for this user.
  startListeningForProfile() {
    if (this.user != null) {
      db.ref("profiles/" + this.urlEventId + "/" + this.user.uid).on("value", snapshot => {
        // FIXME: For some reason this does not trigger when I edit my profile.
        if (snapshot.exists()) {
          this.setState({
            profile: snapshot.val(),
            profileLoading: false
          });
        } else {
          this.setState({
            profile: null,
            profileLoading: false
          });
        }
      });
    }
  }

  // Detach listener for chats for this.user.
  stopListeningForChat() {
    if (this.user != null) {
      db.ref("chats/" + this.urlEventId + "/" + this.user.uid).off();
    }
  }

  // Attach listener for chats for this user.
  startListeningForChat() {
    if (this.user != null) {
      db.ref("chats/" + this.urlEventId + "/" + this.user.uid).on("value", snapshot => {
        if (snapshot.exists()) {
          this.setState({
            chatSessionId: snapshot.val().sessionId,
            chatToken: snapshot.val().token
          });
        } else {
          this.setState({
            chatSessionId: null,
            chatToken: false
          });
        }
      });
    }
  }

  stopListeningForConnected() {
    db.ref(".info/connected").off();
  }

  startListeningForConnected() {
    if (this.user == null) {
      return;
    }
    const participantRef = db.ref("participants/" + this.urlEventId + "/" + this.user.uid);
    db.ref(".info/connected").on("value", function(snapshot) {
      if (snapshot.val() == false) {
        return;
      }

      participantRef.onDisconnect().update(
        {
          connected: false
        }, function(error) {
          // Ignore. Likely means an invalid urlEventId.
        }
      ).then(function() {
        participantRef.update(
          {
            connected: true
          }, function(error) {
            // Ignore. Likely means an invalid urlEventId.
          }
        );
      }).catch(error => {
        // Ignore. Likely means an invalid urlEventId.
      });
    });
  }

  // Add this user as a participant in this event.
  addParticipantInDb() {
    if (this.user != null) {
      db.ref("participants/" + this.urlEventId + "/" + this.user.uid).update({
        available: false,
        chatting: false
      }, function(error) {
        // Ignore. This probably means urlEventId is not a valid event but we've
        // already signed in.
      });
    }
  }

  // Handle an user updating their profile.
  handleUpdateProfile(name, photoUrl, aboutMe) {
    db.ref("profiles/" + this.urlEventId + "/" + this.user.uid).set({
      name: name,
      photo: photoUrl,
      aboutMe: aboutMe
    });
    this.setState({
      profileEditing: false
    });
  }

  handleStartEditingProfile() {
    this.setState({
      profileEditing: true
    });
  }

  handleLogout() {
    const { user } = this.state;
    db.ref("participants/" + this.urlEventId + "/" + user.uid).set({
      connected: false,
      available: false,
      chatting: false
    }, function(error) {
      // Log out regardless of whether there was an error.
      auth().signOut();
    });
  }

  render() {
    if (this.state.userLoading === true || this.state.eventLoading === true) {
      return (
        <div className="spinner-border text-success" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )
    }
    if (this.state.eventName == null) {
      return <Login />;
    }
    if (this.state.user == null) {
      return <Login eventName={this.state.eventName}/>;
    }
    if (this.state.chatSessionId != null && this.state.chatToken != null) {
      return (
        <VideoChat
          eventId={this.urlEventId}
          userId={this.state.user.uid}
          sessionId={this.state.chatSessionId}
          token={this.state.chatToken}
        />
      );
    }
    if (this.state.profileLoading === true) {
      return (
        <div className="spinner-border text-success" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )
    }
    if (this.state.profile == null) {
      return (
        <React.Fragment>
          <NavBar eventName={this.state.eventName} onLogout={this.handleLogout} />
          <ProfilePage
            userId={this.state.user.uid}
            initialName={this.state.user.displayName}
            initialPhoto={this.state.user.photoURL}
            onUpdateProfile={this.handleUpdateProfile}
          />
        </React.Fragment>
      );
    }
    if (this.state.profileEditing) {
      return (
        <React.Fragment>
          <NavBar eventName={this.state.eventName} onLogout={this.handleLogout} />
          <ProfilePage
            userId={this.state.user.uid}
            initialName={this.state.profile.name}
            initialPhoto={this.state.profile.photo}
            initialAboutMe={this.state.profile.aboutMe}
            onUpdateProfile={this.handleUpdateProfile}
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <NavBar eventName={this.state.eventName} onLogout={this.handleLogout} />
        <Lobby
          eventId={this.urlEventId}
          userId={this.state.user.uid}
          onStartEditingProfile={this.handleStartEditingProfile}
        />
      </React.Fragment>
    );
  }
}

export default App;
