import React from 'react';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { db } from '../services/firebase'

const styles = {
  button: {
    margin: 'auto',
  },
};

class SentRequestDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleSentRequestDialogClose = this.handleSentRequestDialogClose.bind(this);
  }

  componentDidMount() {
    const { eventId, userId } = this.props;
    db.ref("participants/" + eventId + "/" + userId).update({
      chatting: true
    });
  }

  componentWillUnmount () {
    const { eventId, userId } = this.props;
    db.ref("participants/" + eventId + "/" + userId).update({
      chatting: false
    }, function(error) {
      // Ignore the error. It's probably permission denied as a result of having
      // already signed out.
    });
  }

  handleSentRequestDialogClose() {
    const { eventId, userId, sentRequestTo } = this.props;
    const updates = {};
    updates["sentRequests/" + eventId + "/" + userId + "/" + sentRequestTo] = null;
    updates["receivedRequests/" + eventId + "/" + sentRequestTo + "/" + userId] = null;
    db.ref().update(updates);
  }

  render () {
    const { classes, sentRequestTo, sentRequestStatus } = this.props;
    let sentRequestDialogTitle = null;
    let sentRequestDialogButton = null;
    if (sentRequestTo != null) {
      if (sentRequestStatus === "requested") {
        sentRequestDialogTitle = "Waiting for response...";
        sentRequestDialogButton = "Cancel Request";
      } else if  (sentRequestStatus === "accepted") {
        sentRequestDialogTitle = "The chat is being set up...";
      } else if  (sentRequestStatus === "declined") {
        sentRequestDialogTitle = "The other user has politely declined";
        sentRequestDialogButton = "Close";
      }
    }
    return (
      <Dialog
        onClose={this.handleSentRequestDialogClose}
        aria-labelledby="dialog-title"
        open={true}
        disableBackdropClick={true}
      >
        <DialogTitle id="dialog-title">{sentRequestDialogTitle}</DialogTitle>
        {sentRequestDialogButton != null &&
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleSentRequestDialogClose}
            className={classes.button}
          >
            {sentRequestDialogButton}
          </Button>
        </DialogActions>
        }
      </Dialog>
    );
  }
}

SentRequestDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  sentRequestTo: PropTypes.string.isRequired,
  sentRequestStatus: PropTypes.string.isRequired,
};

export default withStyles(styles)(SentRequestDialog);