import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import Button from '@material-ui/core/Button';
import Webcam from "react-webcam";
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
});

class ProfilePhotoEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photo: props.initialPhoto,
      takingPhoto: false,
      scale: 1,
    }
    this.editor = null;
    this.webcam = null;
    this.handleNewImage = this.handleNewImage.bind(this);
    this.handleTakingPhoto = this.handleTakingPhoto.bind(this);
    this.handleCapturePhoto = this.handleCapturePhoto.bind(this);
    this.handleScale = this.handleScale.bind(this);
    this.setEditorRef = this.setEditorRef.bind(this);
    this.setWebcamRef = this.setWebcamRef.bind(this);
  }

  handleNewImage(e) {
    this.setState({ photo: e.target.files[0] });
  }

  handleTakingPhoto() {
    this.setState({ takingPhoto: true });
  }

  handleCapturePhoto(e) {
    e.preventDefault();
    const imageSrc = this.webcam.getScreenshot();
    this.setState({
      photo: imageSrc,
      takingPhoto: false
    });
  }

  handleScale(e) {
    const scale = parseFloat(e.target.value)
    this.setState({ scale });
  }

  setEditorRef(editor) {
    if (editor) {
      this.editor = editor;
    }
  }

  setWebcamRef(webcam) {
    if (webcam) {
      this.webcam = webcam;
    }
  }

  getEditor() {
    return this.editor;
  }

  render () {
    const { classes } = this.props;
    const { photo, scale, takingPhoto } = this.state;
    if (takingPhoto) {
      const videoConstraints = {
        width: 288,
        height: 288,
        facingMode: "user"
      };
      return (
        <div className={classes.root}>
          <Button variant="contained" component="label" onClick={this.handleCapturePhoto}>
            Capture Photo
          </Button>
          <Webcam
            audio={false}
            height={288}
            ref={this.setWebcamRef}
            screenshotFormat="image/png"
            width={288}
            videoConstraints={videoConstraints}
            mirrored={true}
          />
          <br />
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <div>
          <Button variant="contained" component="label" className={classes.button}>
            Upload Photo
            <input type="file" accept="image/*" style={{ display: "none" }} onChange={this.handleNewImage} />
          </Button>
          <Button variant="contained" component="label" onClick={this.handleTakingPhoto} className={classes.button}>
            Take Photo
          </Button>
        </div>
        <AvatarEditor
          image={photo}
          ref={this.setEditorRef}
          scale={parseFloat(scale)}
          width={288}
          height={288}
          border={0}
          crossOrigin="anonymous"
        />
        <div>
          <ZoomOut />
          <input
            name="scale"
            type="range"
            onChange={this.handleScale}
            min="1"
            max="3"
            step="0.01"
            defaultValue="1"
          />
          <ZoomIn />
        </div>
      </div>
    );
  }
}

ProfilePhotoEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  initialPhoto: PropTypes.string,
};

export default withStyles(styles)(ProfilePhotoEditor);
