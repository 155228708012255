import React from 'react';
import { Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfilePhotoEditor from '../components/ProfilePhotoEditor';
import { storage } from "../services/firebase";

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(500 + theme.spacing(3 * 2))]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.initialName,
      photo: props.initialPhoto,
      aboutMe: props.initialAboutMe,
    };
    this.profilePhotoEditor = null;
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleAboutMeChange = this.handleAboutMeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setProfilePhotoEditorRef = this.setProfilePhotoEditorRef.bind(this);
  }

  handleNameChange(changeEvent) {
    this.setState({ name: changeEvent.target.value });
  }

  handleAboutMeChange(changeEvent) {
    this.setState({ aboutMe: changeEvent.target.value });
  }

  handleSubmit(submitEvent) {
    submitEvent.preventDefault();
    const { onUpdateProfile, userId } = this.props;
    const { name, aboutMe } = this.state;
    this.profilePhotoEditor.getEditor().getImageScaledToCanvas().toBlob(function(blob) {
      const photoRef = storage.ref().child("images/" + userId + "/profilePhoto.png");
      photoRef.put(blob).then(function() {
        photoRef.getDownloadURL().then(function(downloadURL) {
          onUpdateProfile(name, downloadURL, aboutMe);
        });
      })
    })
  }

  setProfilePhotoEditorRef(profilePhotoEditor) {
    if (profilePhotoEditor) {
      this.profilePhotoEditor = profilePhotoEditor;
    }
  }

  render() {
    const { classes } = this.props;
    const { name, photo, aboutMe } = this.state;
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography variant="h4">
            My Profile
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <ProfilePhotoEditor
              initialPhoto={photo}
              ref={this.setProfilePhotoEditorRef}
            />
            <Typography variant="h6">
              Name
            </Typography>
            <TextField
              name="name"
              value={name}
              onChange={this.handleNameChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <Typography variant="h6">
              About Me
            </Typography>
            <Typography variant="body1">
              Tell us a little about yourself! What are you interested in
              chatting about? What people are you looking to meet?
            </Typography>
            <TextField
              name="aboutMe"
              value={aboutMe}
              onChange={this.handleAboutMeChange}
              fullWidth
              margin="normal"
              multiline
              required
              rows="4"
              rowsMax="8"
              variant="outlined"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {'Save'}
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

ProfilePage.propTypes = {
  classes: PropTypes.shape({
    main: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    submit: PropTypes.string.isRequired,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  initialName: PropTypes.string,
  initialPhoto: PropTypes.string,
  initialAboutMe: PropTypes.string,
  onUpdateProfile: PropTypes.func.isRequired,
};

ProfilePage.defaultProps = {
  initialName: '',
  initialPhoto: '',
  initialAboutMe: '',
};

export default withStyles(styles)(ProfilePage);
