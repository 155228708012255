import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyCnAG8tMFkwUNOd2RhyQpP-VtvB_wITZzI",
  authDomain: "virtual-conference-b3fff.firebaseapp.com",
  databaseURL: "https://virtual-conference-b3fff.firebaseio.com",
  projectId: "virtual-conference-b3fff",
  storageBucket: "virtual-conference-b3fff.appspot.com",
  messagingSenderId: "377540008060",
  appId: "1:377540008060:web:622daa29cfd47d29a691f9",
  measurementId: "G-JDLJ3ZHE4N"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export const auth = firebase.auth;
export const db = firebase.database();
export const storage = firebase.storage();