import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { db } from '../services/firebase'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

const styles = {
  root: {
    height: 512,
    width: 288,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: 1,
    borderRadius: 16
  },
  grayout: {
    opacity: 0.4,
  },
  media: {
    height: 288,
  },
  content: {
    height: 140,
    overflowY: 'auto',
  },
  button: {
    margin: 'auto',
    width: 144,
  },
};

class ProfileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      photo: null,
      aboutMe: null,
    };
    this.handleRequestChat = this.handleRequestChat.bind(this);
  }

  componentDidMount() {
    const { eventId, profileUser } = this.props;
    db.ref("profiles/" + eventId + "/" + profileUser).once("value").then(snapshot => {
      if (snapshot.exists()) {
        const profile = snapshot.val();
        this.setState({
          name: profile.name,
          photo: profile.photo,
          aboutMe: profile.aboutMe,
        });
      }
    });
  }

  componentWillUnmount () {
    const { eventId, profileUser } = this.props;
    db.ref("profiles/" + eventId + "/" + profileUser).off();
  }

  handleRequestChat() {
    const { eventId, userId, profileUser } = this.props;
    const updates = {};
    updates["sentRequests/" + eventId + "/" + userId + "/" + profileUser] = "requested";
    updates["receivedRequests/" + eventId + "/" + profileUser + "/" + userId] = "requested";
    db.ref().update(updates);
  }

  render() {
    const { classes, isSelf, isAvailable, isChatting, onStartEditingProfile } = this.props;
    const { name, photo, aboutMe } = this.state;
    if (name == null && photo == null && aboutMe == null) {
      return (
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
          </Paper>
        </main>
      );
    }
    let buttonText;
    let buttonColor = "primary";
    let buttonDisabled = false;
    let buttonHandler = this.handleRequestChat;
    let rootClassName = classes.root;
    if (isSelf) {
      buttonText = "Edit Profile";
      buttonColor = "secondary";
      buttonHandler = onStartEditingProfile;
    } else if (isChatting) {
      buttonText = "In a Chat";
      buttonDisabled = true;
      rootClassName = `${classes.root} ${classes.grayout}`;
    } else if (isAvailable) {
      buttonText = "Ask to Chat";
    } else {
      buttonText = "Unavailable";
      buttonDisabled = true;
      rootClassName = `${classes.root} ${classes.grayout}`;
    }
    return (
      <Card className={rootClassName}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={photo}
            title={name}
          />
          <CardContent className={classes.content}>
            <Typography gutterBottom variant="h5" component="h2">
              {name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {aboutMe}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            variant="contained"
            color={buttonColor}
            disabled={buttonDisabled}
            onClick={buttonHandler}
            className={classes.button}
          >
            {buttonText}
          </Button>
        </CardActions>
      </Card>
    );
  }
}

ProfileComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  profileUser: PropTypes.string.isRequired,
  isSelf: PropTypes.bool.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  isChatting: PropTypes.bool.isRequired,
  onStartEditingProfile: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProfileComponent);